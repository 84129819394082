<template>
  <div>
    <v-container fluid class="dashboardContent">
      <confirm-dialog
        v-bind:showDialog="refreshCacheDialog"
        v-bind:onConfirm="refreshCache"
        v-bind:onCancel="closeRefreshCacheDialog"
      ></confirm-dialog>
      <v-dialog
        v-model="warnCancelRefresh"
        width="500"
      >
        <v-card>
          <v-card-title>{{ resources.REFRESH_CANCEL }}</v-card-title>
          <v-card-text>{{ resources.CONFIRM_CANCEL_REFRESH }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="warnCancelRefresh = false">{{ resources.CANCEL }}</v-btn>
            <v-btn color="error darken-1" text @click="cancelRefreshCache">{{ resources.CONFIRM }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row v-if="!loading">
        <v-col cols="4" sm="4" md="3" lg="3">
          <status-update-card
            :fileTypeNames="fileTypeNames"
            :refreshStatus="refreshStatus"
          ></status-update-card>
        </v-col>
        <v-col cols="10" sm="8" md="9" lg="9">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                v-on:click="deployFiles"
                :loading="deployingFiles"
                id="deployFilesButton"
                dark
                x-large
                color="primary"
              >
                {{ resources.DEPLOY_FILES }}
              </v-btn>
              <v-btn
                v-on:click="showRefreshCacheDialog"
                :disabled="!refreshCacheEnabled||refreshStatusUnknown"
                :dark="refreshCacheEnabled||refreshStatusUnknown"
                x-large
                color="primary"
                v-if="refreshStatus!==resources.STATUS_RUNNING"
              >
                {{ resources.REFRESH_CACHE }}
              </v-btn>
              <v-btn
                  v-on:click="warnCancelRefresh = true"
                  :disabled="refreshCacheEnabled||refreshStatusUnknown"
                  :dark="!refreshCacheEnabled||refreshStatusUnknown"
                  x-large
                  color="error"
                  v-else
              >
                {{ resources.REFRESH_CANCEL }}
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-for="fileType in Object.values(fileTypes)"
              v-bind:key="fileType.title"
              cols="6"
              sm="12"
              md="6"
            >
              <file-upload-card
                v-bind:title="`${fileType.title}`"
                v-bind:metadataOfExistingFiles="
                  fileType.metadataOfExistingFiles
                "
                v-bind:url="`${fileType.url}`"
                v-bind:options="{
                  ...commonDropzoneOptions,
                  ...fileType.dropzoneOptions,
                }"
                v-bind:failedToDeploy="fileType.failedToDeploy"
              ></file-upload-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col class="spinnerContainer" cols="12" sm="12" md="12">
          <v-progress-circular indeterminate color="primary" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FileUploadCard from "@/components/FileUploadCard";
import StatusUpdateCard from "@/components/StatusUpdateCard";
import * as resources from "@/_constants/resources";
import { SERVER_URL, UPDATE_FILE_PATH } from "@/_constants/endpoints";
import { FileService } from "@/_services/";
import ConfirmDialog from "./ConfirmDialog.vue";
import {stopRefreshCache} from "@/_services/file.service";

export default {
  components: { FileUploadCard, StatusUpdateCard, ConfirmDialog },
  created() {
    this.initialize();
  },
  data() {
    return {
      loading: true,
      deployingFiles: false,
      resources: resources,
      fileTypeNames: [],
      commonDropzoneOptions: {
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("token")}`,
        },
        addRemoveLinks: true,
        method: "PUT",
        paramName: "files",
        createImageThumbnails: false,
        timeout: 3600000, // Times out at 1 hour
      },
      fileTypes: {},
      refreshCacheDialog: false,
      refreshStatusInterval: null,
      warnCancelRefresh: false,
    };
  },

  computed: {
    ...mapGetters({
      refreshStatus: "status/getStatus",
      refreshError: "status/getError",
    }),
    refreshCacheEnabled: function() {
      return this.refreshStatus === resources.STATUS_IDLE;
    },
    refreshStatusUnknown: function() {
      return this.refreshStatus === resources.STATUS_UNKNOWN;
    }
  },

  watch: {
    refreshCacheDialog(val) {
      val || this.closeRefreshCacheDialog();
    },
    refreshError(val) {
      this.showErrorMessage(val);
    }
  },

  beforeDestroy() {
    clearInterval(this.refreshStatusInterval);
  },

  methods: {
    ...mapActions({
      showSuccessMessage: "alert/success",
      showErrorMessage: "alert/error",
      loadDataSources: "dataSources/load",
      initializeStatus: "status/init",
    }),
    async initialize() {
      this.loading = true;
      this.initializeStatus();

      try {
        let fetchedFileRefInfo = await FileService.fetchFileTypes();
        this.fileTypeNames = fetchedFileRefInfo.map(({ fileRef }) => fileRef);
        let layerNames = fetchedFileRefInfo.map(({ fileRef, layers }) => `${fileRef}.${layers[0]}`)
        this.loadDataSources(layerNames);

        await this.fetchStagedFiles();
      } catch (err) {
        if (err.status && err.statusText) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        } else {
          this.showErrorMessage(resources.GENERIC_ERROR);
        }

        console.log(err);
      }

      this.loading = false;
    },

    async fetchStagedFiles() {
      for await (const fileType of this.fileTypeNames) {
        let fileTypeUrl = `${SERVER_URL}${UPDATE_FILE_PATH(fileType)}`;
        let metadataOfExistingFiles = await FileService.fetchStagedFileMetadata(
          fileType
        );

        this.fileTypes[fileType] = {
          title: fileType,
          url: fileTypeUrl,
          metadataOfExistingFiles: metadataOfExistingFiles.active,
          dropzoneOptions: {
            url: fileTypeUrl,
          },
          failedToDeploy:
            metadataOfExistingFiles.active.length > 0 && this.deployingFiles
              ? true
              : false,
        };
      }
    },

    async deployFiles() {
      this.deployingFiles = true;

      try {
        await FileService.deployFiles();

        this.showSuccessMessage(resources.DEPLOY_SUCCESS);
      } catch (err) {
        if (err.status && err.statusText) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        } else {
          this.showErrorMessage(resources.GENERIC_ERROR);
        }
        console.log(err);
      }

      // Re-check files that are in staging
      // - if deploy was successful, all staged files should be wiped
      // - if deploy returned error, erroneous files should remain in staging
      try {
        await this.fetchStagedFiles();
      } catch (err) {
        if (err.status && err.statusText) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        } else {
          this.showErrorMessage(resources.GENERIC_ERROR);
        }
        console.log(err);
      }

      this.deployingFiles = false;
    },

    async showRefreshCacheDialog() {
      this.refreshCacheDialog = true;
    },

    closeRefreshCacheDialog() {
      this.refreshCacheDialog = false;
    },

    async refreshCache(dataSourceNames) {
      try {
        await FileService.refreshCache(dataSourceNames);
      } catch (err) {
        if (err.status && err.statusText) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        } else {
          this.showErrorMessage(`${resources.GENERIC_ERROR}`);
        }

        console.log(err);
      }

      this.closeRefreshCacheDialog();
    },

    async cancelRefreshCache(){
      this.warnCancelRefresh = false;
      try{
        await stopRefreshCache();
      } catch (err) {
        console.error(err);
        if (err.status && err.statusText) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        } else {
          this.showErrorMessage(`${resources.GENERIC_ERROR}`);
        }
      }
    }

  },
};
</script>

<style scoped>
.dashboardContent {
  margin: 0 0 0 0 !important;
}
.spinnerContainer {
  display: flex;
  justify-content: center;
  height: 90vh;
  align-items: center;
}

#deployFilesButton {
  margin-right: 10px;
}
</style>
