export const SERVER_URL = "https://admin.ava-proto.com";

export const USER_LOGIN_PATH = "/auth/token";
export const USER_PATH = "/user";
export const SPECIFIC_USER_PATH = (username) => `/user/${username}`;

export const DEPLOYED_FILES_PATH = (fileType) => `/updates/files/${fileType}`;
export const FILE_REF_NAMES_PATH = "/updates/files/";
export const UPDATE_FILE_PATH = (file) => `/updates/stage/${file}`;
export const DEPLOY_FILES_PATH = "/updates/deploy";
export const DELETE_STAGED_FILE_PATH = (fileType, fileName) => `/updates/stage/${fileType}/${fileName}`;
export const REFRESH_CACHE_PATH = "/updates/refresh";
export const REFRESH_STATUS_PATH = "/updates/status";

export const CONFIG_PATH = "/config";
export const CONFIG_PROPERTY_PATH = (key) => `/config/${key}`;
