<template>
  <v-card class="serverStatus">
    <v-card-title>
      {{ resources.SERVER }}
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row class="updateRow mb-2">
          <span class="updateLabel font-weight-bold">
            {{ resources.REFRESH_STATUS }}
          </span>
          <v-chip
            v-if="refreshStatus != undefined && refreshStatus != ''"
            :color="getStatusColor()"
            dark
          >
            {{ refreshStatus }}
          </v-chip>
          <v-chip v-else color="grey" dark>
            <v-progress-circular
              :size="25"
              :width="2"
              indeterminate
              color="onPrimary"
            />
          </v-chip>
        </v-row>
        <v-row>
          <v-divider class="mb-4 pt-2"></v-divider>
        </v-row>
        <v-row class="updateRow mb-2">
          <span class="updateLabel font-weight-bold">
            {{ resources.FILE_INFORMATION }}
          </span>
        </v-row>
        <v-row>
          <v-expansion-panels multiple focusable>
            <v-expansion-panel
              v-for="fileMetadata in filesMetadata"
              :key="fileMetadata.fileType"
            >
              <v-expansion-panel-header>
                {{ fileMetadata.fileType }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-container>
                  <v-row>
                    <span class="deployedFileName font-weight-bold">
                      {{
                        `${resources.DEPLOYED_FILES}: ( ${fileMetadata.numberOfFiles} )`
                      }}
                    </span>
                  </v-row>
                  <v-row>
                    <v-list-item
                      v-for="file in fileMetadata.files"
                      v-bind:key="file.fileName"
                    >
                      <span class="deployedFileName">
                        {{ file.fileName }}
                      </span>
                    </v-list-item>
                  </v-row>
                </v-container>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import * as resources from "@/_constants/resources";
import { FileService } from "@/_services/";

export default {
  name: "StatusUpdateCard",
  props: {
    fileTypeNames: Array,
    refreshStatus: String,
  },
  data() {
    return {
      loading: false,
      resources: resources,
      filesMetadata: [],
      lastUpdated: new Date().toLocaleString(),
      numberOfFiles: 0,
      files: [],
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    async initialize() {
      this.loading = true;

      try {
        for (const fileType of this.fileTypeNames) {
          let fileMetadata = await FileService.fetchDeployedFileMetadata(
            fileType
          );
          fileMetadata = fileMetadata.active;

          this.filesMetadata.push({
            fileType: fileType,
            numberOfFiles: fileMetadata.fileCount,
            files: fileMetadata.files,
          });
        }
      } catch (err) {
        console.log(err);
      }

      this.loading = false;
    },

    getStatusColor() {
      switch (this.refreshStatus) {
        case resources.STATUS_IDLE:
          return "green";
        case resources.STATUS_RUNNING:
          return "blue";
        default:
          return "grey";
      }
    },
  },
};
</script>

<style scoped>
.serverStatus {
  height: 90vh;
}

.updateLabel {
  margin-right: 10px;
}

.updateRow {
  align-items: center;
}

.v-list-item {
  min-height: unset;
}

.v-expansion-panel-content {
  overflow: scroll;
}

.v-card {
  overflow: scroll;
}

.deployedFileName {
  white-space: nowrap;
}
</style>
