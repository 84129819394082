<template>
  <div class="dataTableContainer">
    <v-card class="dataTable">
      <v-card-title>
        {{ `${resources.USERS} (${users.length})` }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          v-bind:label="`${resources.SEARCH}`"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :search="search"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ resources.CREATE_USER }}
                </v-btn>
              </template>

              <v-card>
                <v-card-title>
                  <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <validation-observer ref="observer">
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <validation-provider
                            v-slot="{ errors }"
                            v-bind:name="`${resources.USERNAME}`"
                            rules="required"
                            class="form-element"
                          >
                            <v-text-field
                              v-model="editedItem.username"
                              v-bind:label="`${resources.USERNAME}`"
                              :error-messages="errors"
                              required
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <v-checkbox
                            v-model="editedItem.isActive"
                            label="Active"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="6" md="6">
                          <validation-provider
                            vid="password"
                            v-bind:name="`${resources.PASSWORD}`"
                            v-slot="{ errors }"
                            :rules="{ required: isEdit ? false : true }"
                            class="form-element"
                          >
                            <v-text-field
                              v-model="editedItem.password"
                              :error-messages="errors"
                              v-bind:label="`${resources.PASSWORD}`"
                              :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              @click:append="showPassword = !showPassword"
                              :type="showPassword ? 'text' : 'password'"
                              ref="password"
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="6" sm="6" md="6">
                          <validation-provider
                            v-bind:name="`${resources.CONFIRM_PASSWORD}`"
                            :rules="{
                              passwordConfirmed: { password: '@password' },
                              required:
                                !isEdit || editedItem.password ? true : false,
                            }"
                            v-slot="{ errors }"
                            class="form-element"
                          >
                            <v-text-field
                              v-model="editedItem.confirmedPassword"
                              :error-messages="errors"
                              v-bind:label="`${resources.CONFIRM_PASSWORD}`"
                              :append-icon="
                                showConfirmedPassword
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              @click:append="
                                showConfirmedPassword = !showConfirmedPassword
                              "
                              :type="
                                showConfirmedPassword ? 'text' : 'password'
                              "
                            ></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </validation-observer>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="close">
                    {{ resources.CANCEL }}
                  </v-btn>
                  <v-btn color="blue darken-1" text @click="save">
                    {{ resources.SAVE }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <confirm-dialog
              v-bind:showDialog="dialogDelete"
              v-bind:onConfirm="deleteItemConfirm"
              v-bind:onCancel="closeDelete"
              v-bind:confirmationMessage="`${resources.CONFIRM_USER_DELETE}`"
              v-bind:confirmText="`${resources.CONFIRM}`"
              v-bind:cancelText="`${resources.CANCEL}`"
            ></confirm-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-chip :color="getColor(item.isActive)" dark>
            {{ item.isActive ? resources.ACTIVE : resources.INACTIVE }}
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteItem(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { UserService } from "@/_services";
import ConfirmDialog from "./ConfirmDialog.vue";
import * as resources from "@/_constants/resources";
import { mapActions } from "vuex";
import { required } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";

setInteractionMode("passive");

extend("passwordConfirmed", {
  params: ["password", "confirmedPassword"],
  validate: (value, { password }) => {
    if (value !== password) {
      return false;
    }
    return true;
  },
  message: resources.PASSWORDS_MUST_MATCH,
});

extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  components: {
    ConfirmDialog,
    ValidationProvider,
    ValidationObserver,
  },
  name: "UsersTable",
  data: () => ({
    resources: resources,
    search: "",
    headers: [
      { text: resources.USERNAME, value: "username" },
      { text: resources.ACTIVE, value: "isActive" },
      { text: resources.ACTIONS, value: "actions", sortable: false },
    ],
    users: [],
    loading: false,
    dialog: false,
    dialogDelete: false,
    showPassword: false,
    showConfirmedPassword: false,
    editedIndex: -1,
    editedItem: {
      username: "",
      isActive: false,
    },
    defaultItem: {
      username: "",
      isActive: false,
    },
  }),

  created() {
    this.initialize();
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  computed: {
    isEdit: function() {
      return this.editedIndex > -1;
    },
    formTitle() {
      return this.editedIndex === -1
        ? resources.CREATE_USER
        : resources.EDIT_USER;
    },
  },

  methods: {
    ...mapActions({
      showSuccessMessage: "alert/success",
      showErrorMessage: "alert/error",
    }),
    async initialize() {
      try {
        this.loading = true;

        let fetchedUsers = await UserService.fetchAllUsers();
        this.users = fetchedUsers;
      } catch (err) {
        this.showErrorMessage(`${err.status}: ${err.statusText}`);
      }

      this.loading = false;
    },

    getColor(isActive) {
      if (isActive) {
        return "green";
      } else {
        return "grey";
      }
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      try {
        let deletedUser = this.users[this.editedIndex];
        await UserService.deleteUser(deletedUser);

        this.users.splice(this.editedIndex, 1);
      } catch (err) {
        this.showErrorMessage(`${err.status}: ${err.statusText}`);
      }

      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        try {
          let user = this.editedItem;

          if (this.isEdit) {
            this.loading = true;

            let oldUser = this.users[this.editedIndex];

            delete user.confirmedPassword;
            if (oldUser.username == user.username) {
              delete user.username;
            }

            await UserService.updateUser(oldUser, user);

            delete user.password;

            Object.assign(this.users[this.editedIndex], user);
          } else {
            this.loading = true;

            await UserService.createUser(user);

            delete user.password;
            delete user.confirmedPassword;

            this.users.push(user);
          }
        } catch (err) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        }

        this.close();
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.dataTableContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

.dataTable {
  width: 75vw;
}
</style>
