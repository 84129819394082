<template>
  <div class="loginContainer">
    <validation-observer ref="observer">
      <v-form class="loginContent" @submit.prevent="submit">
        <div class="form-group">
          <v-avatar class="icon" color="primary" size="48">
            <v-icon v-if="!loading" dark>
              mdi-sail-boat
            </v-icon>
            <v-progress-circular v-else indeterminate color="onPrimary" />
          </v-avatar>
          <validation-provider
            v-slot="{ errors }"
            :name="`${resources.USERNAME}`"
            rules="required"
            class="form-element"
          >
            <v-text-field
              v-model="email"
              :error-messages="errors"
              :label="`${resources.USERNAME}`"
              required
              filled
              dense
            ></v-text-field>
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            :name="`${resources.PASSWORD}`"
            rules="required"
            class="form-element"
          >
            <v-text-field
              v-model="password"
              :error-messages="errors"
              :label="`${resources.PASSWORD}`"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showPassword = !showPassword"
              required
              dense
              filled
              :type="showPassword ? 'text' : 'password'"
            ></v-text-field>
          </validation-provider>
          <v-btn class="signin-btn" block color="primary" type="submit">
            {{ resources.SIGN_IN }}
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationProvider,
  setInteractionMode,
  ValidationObserver,
} from "vee-validate";
import { UserService } from "@/_services";
import router from "@/router";
import * as routes from "@/_constants/routes";
import * as resources from "@/_constants/resources";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    resources: resources,
    routes: routes,
    email: "",
    password: null,
    showPassword: false,
    loading: false,
  }),
  computed: {
    params() {
      return {
        email: this.email,
        password: this.password,
      };
    },
  },
  methods: {
    ...mapActions({
      showSuccessMessage: "alert/success",
      showErrorMessage: "alert/error",
    }),
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (valid) {
        this.loading = true;
        try {
          let token = await UserService.login(this.email, this.password);
          const { accessToken } = token;

          if (accessToken) {
            window.localStorage.setItem("token", accessToken);

            router.push(`${routes.DASHBOARD}`);
          }
        } catch (err) {
          this.showErrorMessage(`${err.status}: ${err.statusText}`);
        }

        this.loading = false;
      }
    },
    clear() {
      this.email = "";
      this.password = null;
      this.$refs.observer.reset();
    },
  },
};
</script>

<style>
.loginContainer {
  display: flex;
  justify-content: center;
}

.loginContent {
  width: 70vw;
  max-width: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.icon {
  margin-bottom: 20px;
}

.form-element {
  flex: 0 0 100%;
  margin-bottom: 5px;
}

.signin-btn {
  width: 100%;
}
</style>
