// labels
export const USERS = "Users";
export const CREATE_USER = "Create User";
export const EDIT_USER = "Edit User";
export const ACTIVE = "Active";
export const INACTIVE = "Inactive";
export const USERNAME = "Username";
export const PASSWORD = "Password";
export const CANCEL = "Cancel";
export const CONFIRM = "OK";
export const SAVE = "Save";
export const SEARCH = "Search";
export const REFRESH_STATUS = "Refresh Status:";
export const ACTIONS = "Actions";
export const SERVER = "Server";
export const SERVER_FILE_INFO = "File Info";
export const DEPLOYED_FILES = "Deployed Files";
export const DEPLOY_FILES = "Deploy Files";
export const LAST_UPDATED = "Last Updated";
export const FILE_INFORMATION = "File Info";
export const CONFIRM_PASSWORD = "Re-type Password";
export const SIGN_IN = "Sign In";
export const REFRESH_CACHE = "Refresh Cache";
export const REFRESH_CANCEL = "Cancel Refresh";

//server statuses
export const STATUS_IDLE = "Idle";
export const STATUS_RUNNING = "Running";
export const STATUS_UNKNOWN = "Unknown";

// messages
export const CONFIRM_USER_DELETE = "Are you sure you want to delete this user?";
export const CONFIRM_REFRESH_CACHE =
  "This process takes several hours and may cause images to appear disconnected during this time, do you wish to continue?";
export const CONFIRM_CANCEL_REFRESH =
    "This process will stop your current refresh process and will not be saved, do you wish to continue?";
export const SELECT_REFRESH_LAYERS = "Select layers to refresh"

// alert messages
export const GENERIC_ERROR = "An error has occurred.";
export const ERROR_WITH_STATUS_REFRESH =
  "Error: Unable to load refresh status.";
export const STAGE_SUCCESS = "File has been staged!";
export const DEPLOY_SUCCESS = "Files have been deployed!";
export const DEPLOY_ERROR = "Failed to deploy file.";
export const CANCELED_UPLOAD_ERROR = "File upload has been canceled.";

// validation messages
export const PASSWORDS_MUST_MATCH = "Passwords must match!";

// file types
export const BMIO_SOUNDINGS = "bMIO Soundings";
export const COMBINED_SURFACE = "Combined Surface";
export const DIFFERENCE_SURFACE = "Difference Surface";
export const CONFORMANCE_SURFACE = "Conformance Surface";

// numbers
export const STATUS_REFRESH_INTERVAL = 10000;
