<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title>{{ resources.REFRESH_CACHE }}</v-card-title>
      <v-card-subtitle>{{ resources.CONFIRM_REFRESH_CACHE }}</v-card-subtitle>
      <v-card-text>
        <v-row v-if="!loading">
          <v-col cols="12" sm="12" md="12">
            <v-list flat dense class="max-height-500 overflow-y-auto">
              <v-list-item-group v-model="refreshLayers" multiple>
                <template v-for="(datasource) in dataSources">
                  <v-list-item :key="datasource">
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active" color="primary"></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ datasource }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col class="spinnerContainer" cols="12" sm="12" md="12">
            <v-progress-circular indeterminate color="primary" />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onCancel">{{ resources.CANCEL }}</v-btn>
        <v-btn color="blue darken-1" text @click="loadDataSourceNames">{{ resources.CONFIRM }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as resources from "@/_constants/resources";
import {FileService} from "@/_services";

export default {
  name: "ConfirmDialog",
  props: {
      showDialog: Boolean,
      confirmTitle: String,
      confirmationMessage: String, 
      confirmText: String,
      cancelText: String,
      onConfirm: Function,
      onCancel: Function
  },
  data() {
    return {
      resources: resources,
      refreshLayers: [],
      selectedLayerNames: [],
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      getDataSources: 'dataSources/getDataSources',
    }),
    dataSources() {
      let dsSorted = this.getDataSources.map(r => r.split(".")[0]);
      dsSorted.sort();
      return dsSorted;
    }
  },
  watch: {
    showDialog(updatedState) {
      updatedState && this.initialize();
    },
    refreshLayers(updatedIndexes){
      let _t = this;
      this.selectedLayerNames = updatedIndexes
          .map(i => _t.getDataSources
              .find(dsLyr => dsLyr.startsWith(_t.dataSources[i])));
    }
  },
  methods: {
    ...mapActions({
      loadDataSources: "dataSources/load"
    }),
    async initialize() {
      this.loading = true;
      let fetchedDeployStatus = await FileService.fetchDeployStatus();
      let ds = this.dataSources;
      this.refreshLayers = fetchedDeployStatus.deployed
          .map(r => ds.indexOf(r.datasource.split(".", 1)[0]));
      if(this.refreshLayers === undefined)throw TypeError("List of Layers must be a list");
      this.loading = false;
    },
    async loadDataSourceNames(){
      this.onConfirm(this.selectedLayerNames);
    }
  }
}
</script>

<style>
  .max-height-500 {
    max-height: 500px;
  }
  .spinnerContainer {
    display: flex;
    justify-content: center;
    height: 500px;
    align-items: center;
  }
</style>