import Vue from "vue";
import Vuex from "vuex";
import { alert } from "./modules/alert.module";
import { dataSources } from "./modules/datasources.module";
import { status } from "./modules/status.module";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    alert,
    dataSources,
    status,
  },
});
