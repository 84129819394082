const state = {
  type: null,
  message: null
};

const actions = {
  success ({ commit }, message) {
    commit('success', message);
    setTimeout(function () {
      commit('clear');
    }, 5000);
  },
  error ({ commit }, message) {
    commit('error', message);
    setTimeout(function () {
      commit('clear');
    }, 5000);
  },
  clear ({ commit }, message) {
    commit('clear');
  }
};

const mutations = {
  success (state, message) {
    state.type = 'success';
    state.message = message;
  },
  error (state, message) {
    state.type = 'error';
    state.message = message;
  },
  clear (state) {
    state.type = null;
    state.message = null;
  }
};

export const alert = {
  namespaced: true,
  state,
  actions,
  mutations
};
