import API from './API';
import {
  USER_PATH,
  SPECIFIC_USER_PATH,
  USER_LOGIN_PATH,
} from '@/_constants/endpoints';

export async function login (username, password) {
  try {
    let userData = new FormData();
    userData.append('username', username);
    userData.append('password', password);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };

    let response = await API.post(USER_LOGIN_PATH, userData, config);

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function fetchAllUsers () {
  try {
    let response = await API.get(USER_PATH);

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function updateUser (oldUser, user) {
  try {
    let response = await API.put(SPECIFIC_USER_PATH(oldUser.username), user);

    return response;
  } catch (err) {
    throw err.response;
  }
}

export async function createUser (user) {
  try {
    let response = await API.post(USER_PATH, user);

    return response;
  } catch (err) {
    throw err.response;
  }
}

export async function deleteUser (user) {
  try {
    let response = await API.delete(SPECIFIC_USER_PATH(user.username));

    return response;
  } catch (err) {
    throw err.response;
  }
}
