<template>
  <v-tabs dark background-color="primary">
    <v-avatar 
      class="icon"
      color="primary" 
      size="48">
      <v-icon dark>
        mdi-sail-boat
      </v-icon>
    </v-avatar>
    <v-tab :to="{path: routes.DASHBOARD}">Dashboard</v-tab>
    <v-tab :to="{path: routes.USERS}">Users</v-tab>
    <div class="buttonContainer">
      <v-btn
        @click="signOut"
        class="signOutButton"
        elevation="0"
        color="primary">
        Sign Out
      </v-btn>
    </div>
  </v-tabs>
</template>

<script>
import * as routes from '@/_constants/routes';
import router from '@/router';

export default {
  name: 'NavMenu',
  data: () => ({
    routes: routes,
  }),
  methods: {
    signOut() {
      localStorage.clear();
      router.push(routes.LOGIN);
    }
  }
}
</script>

<style>
.buttonContainer {
  display: flex;
  flex-grow: inherit;
  justify-content: flex-end;
  align-items: center;
}

.signOutButton {
  height: 100%;
}
</style>
