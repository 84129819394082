import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

let customColors = {
  lightGrey: '#eeeeee',
  darkGrey: '#37474f',
  darkestGrey: '#102027'
};

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: {
        primary: customColors.darkestGrey,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        onPrimary: colors.shades.white,
        background: customColors.lightGrey
      },
      dark: {
        primary: customColors.darkestGrey,
        background: customColors.darkGrey
      },
    },
  },
})

export default vuetify;
