import API from "./API";
import {
  UPDATE_FILE_PATH,
  FILE_REF_NAMES_PATH,
  DEPLOY_FILES_PATH,
  REFRESH_CACHE_PATH,
  DEPLOYED_FILES_PATH,
  DELETE_STAGED_FILE_PATH,
  REFRESH_STATUS_PATH,
} from "@/_constants/endpoints";

export async function fetchDeployedFileMetadata(fileType) {
  try {
    let response = await API.get(DEPLOYED_FILES_PATH(fileType));

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function deleteStagedFile(fileType, fileName) {
  try {
    let response = await API.delete(
      DELETE_STAGED_FILE_PATH(fileType, fileName)
    );

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function fetchFileTypes() {
  try {
    let response = await API.get(FILE_REF_NAMES_PATH);

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function fetchStagedFileMetadata(fileType) {
  try {
    let response = await API.get(UPDATE_FILE_PATH(fileType));

    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function fetchDeployStatus() {
  try {
    let response = await API.get(DEPLOY_FILES_PATH);
    return response.data;
  } catch (err) {
    throw err.response;
  }
}

export async function deployFiles() {
  try {
    let response = await API.put(DEPLOY_FILES_PATH);

    return response;
  } catch (err) {
    throw err.response;
  }
}

export async function refreshCache(dataSources) {
  try {
    let response = await API.put(REFRESH_CACHE_PATH, dataSources);

    return response;
  } catch (err) {
    throw err.response;
  }
}

export async function stopRefreshCache() {
  try {
    return await API.delete(REFRESH_CACHE_PATH);
  } catch (err) {
    throw err.response;
  }
}

export async function fetchRefreshStatus() {
  try {
    let response = await API.get(REFRESH_STATUS_PATH);

    return response.data;
  } catch (err) {
    throw err.response;
  }
}