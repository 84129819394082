const state = {
  dataSources: [],
};

const getters = {
  getDataSources(state) {
    return state.dataSources;
  }
}

const actions = {
  load ({ commit }, dataSources) {
    commit('load', dataSources);
  },

};

const mutations = {
  load (state, dataSources) {
    state.dataSources = dataSources;
  },
  select (state, dataSourceName) {
    let curIndex = state.selections.indexOf(dataSourceName)
    if(curIndex >= 0) {
      delete state.selections[curIndex];
    } else {
      state.selection.push(dataSourceName);
    }
  }
};

export const dataSources = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
