import axios from "axios";
import router from "@/router";
import * as routes from "@/_constants/routes";
import { SERVER_URL } from "@/_constants/endpoints";
import applyCaseMiddleware from "axios-case-converter";

const unprotectedUrls = [/\/auth\/token$/];

const instance = axios.create({
  baseURL: SERVER_URL,
});

applyCaseMiddleware(instance);

instance.interceptors.request.use(
  async (req) => {
    let isUnprotected = unprotectedUrls.some((rx) => rx.test(req.url));

    if (!isUnprotected) {
      let token = window.localStorage.getItem("token");

      if (!token) {
        window.localStorage.clear();
        router.push(routes.LOGIN);
      } else {
        req.headers["Authorization"] = "Bearer " + token;
      }
    }
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  (error) => {
    if (error.response && error.response.status == 401) {
      window.localStorage.clear();

      let isUnprotected = unprotectedUrls.some((rx) =>
        rx.test(error.response.config.url)
      );

      if (!isUnprotected) {
        router.push(routes.LOGIN);
      }
    }
    return Promise.reject(error);
  }
);

export default instance;
