<template>
  <v-alert
    :type="alert.type || 'error'"
    :value="alert.message != null && alert.message != ''"
    transition="fab-transition"
  >
    {{ alert.message }}
  </v-alert>
</template>

<script>
export default {
  name: "Alert",
  props: {
    alert: Object,
  },
};
</script>

<style scoped>
.v-alert {
  position: fixed;
  bottom: 30px;
  align-self: center;
  z-index: 10000000000000;
}
</style>
