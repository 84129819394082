import Vue from "vue";
import Router from "vue-router";
import Dashboard from "@/views/Dashboard";
import Users from "@/views/Users";
import Login from "@/views/Login";
import * as routes from "@/_constants/routes";

Vue.use(Router);

const requireAuth = true;

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: "/", 
      name: "root",
      component: Dashboard,
      meta: { requiresAuth: true }
    }, 
    {
      path: routes.DASHBOARD, 
      name: "dashboard",
      component: Dashboard,
      meta: { requiresAuth: requireAuth }
    }, 
    {
      path: routes.USERS, 
      name: "users",
      component: Users,
      meta: { requiresAuth: requireAuth }
    }, 
    {
      path: routes.LOGIN, 
      name: "login",
      component: Login,
      meta: { requiresAuth: false }
    }, 
  ]
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const loggedIn = localStorage.getItem('token');
    if (!loggedIn) {
      next({
        path: routes.LOGIN
      });
    } 
    else {
      next();
    }
  }
  else {
    next();
  }
});

export default router;
