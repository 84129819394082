import { STATUS_IDLE, STATUS_UNKNOWN, STATUS_REFRESH_INTERVAL } from "@/_constants/resources";
import { FileService } from "@/_services";
import * as resources from "@/_constants/resources";


const state = {
    currentState: STATUS_IDLE,
    errorMessage: null,
};

const getters = {
    getStatus(state) {
        return state.currentState;
    },
    getError(state) {
        return state.errorMessage;
    }
}

const actions = {
    async init ({ commit }) {
        const refresh = async () => {
            if (!(window.localStorage.getItem("token"))) return;
            try {
                let refreshStatus = await FileService.fetchRefreshStatus();
                commit("setStatus", refreshStatus);
            } catch (err) {
                if (err.status && err.statusText) {
                    commit("setError", `${err.status}: ${err.statusText}`);
                } else {
                    commit("setError", `${resources.ERROR_WITH_STATUS_REFRESH}`);
                }
                console.error(err);
            }
        };
        await refresh();
        setInterval(refresh, STATUS_REFRESH_INTERVAL);
    }
};

const mutations = {
    setStatus (state, newStatus ) {
        state.currentState = newStatus;
        state.errorMessage = null;
    },
    setError (state, errorMessage) {
        state.currentState = STATUS_UNKNOWN;
        state.errorMessage = errorMessage;
    }
};

export const status = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
