<template>
  <v-app
    id="main"
    :style="{ background: $vuetify.theme.themes[theme].background }"
  >
    <Alert v-bind:alert="alert" />
    <keep-alive v-if="isLoggedIn">
      <router-view></router-view>
    </keep-alive>
    <router-view v-else></router-view>
  </v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Alert from "@/components/Alert.vue";

export default {
  name: "App",
  components: { Alert },
  data() {
    return {
      isLoggedIn: false,
    };
  },
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
  methods: {
    ...mapActions({
      clearAlert: "alert/clear",
    }),
  },
  watch: {
    $route() {
      if (window.localStorage.getItem("token")) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
      // clear alert on location change
      this.clearAlert();
    },
  },
};
</script>
