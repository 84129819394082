<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <vue-dropzone
        id="dropzone"
        class="vue-dropzone dropzone"
        :ref="title"
        :options="options"
        :duplicateCheck="false"
        :destroyDropzone="false"
        @vdropzone-success="vsuccess"
        @vdropzone-error="verror"
        @vdropzone-removed-file="vremoved"
        @vdropzone-canceled="vcanceled"
      >
      </vue-dropzone>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import { FileService } from "@/_services/";
import vueDropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import * as resources from "@/_constants/resources";

export default {
  name: "FileUploadCard",
  components: { vueDropzone },
  props: {
    title: String,
    url: String,
    options: Object,
    metadataOfExistingFiles: Object,
    failedToDeploy: Boolean,
  },

  data() {
    return {
      resources: resources,
      ok: true,
      success: false,
      error: false,
      errorFiles: [],
      disableFileRemovalFromServer: false,
    };
  },

  computed: {
    stagedFiles() {
      return this.metadataOfExistingFiles.files;
    },
  },

  watch: {
    stagedFiles(updatedStagedFiles) {
      this.disableFileRemovalFromServer = true;

      this.$refs[`${this.title}`].removeAllFiles();

      // re-add updated files
      updatedStagedFiles.forEach((fileMetaData) => {
        const file = {
          size: fileMetaData.size,
          name: fileMetaData.fileName,
        };
        let url = this.url;
        this.$refs[`${this.title}`].manuallyAddFile(file, url);
        this.$refs[`${this.title}`].dropzone.options.addRemoveLinks = true;

        if (this.failedToDeploy) {
          let dropzoneFile = this.$refs[`${this.title}`].dropzone.files.find(
            (dzFile) => dzFile.name == file.name
          );
          const errorMessage = resources.DEPLOY_ERROR;

          dropzoneFile.previewElement.classList.add("dz-error");

          dropzoneFile.previewElement.querySelectorAll(
            ".dz-error-message span"
          )[0].textContent = errorMessage;
        }
      });

      this.disableFileRemovalFromServer = false;
    },
  },

  mounted() {
    let { files, fileCount } = this.metadataOfExistingFiles;

    if (fileCount > 0) {
      files.forEach((fileMetaData) => {
        const file = {
          size: fileMetaData.size,
          name: fileMetaData.fileName,
        };
        let url = this.url;
        this.$refs[`${this.title}`].manuallyAddFile(file, url);
        this.$refs[`${this.title}`].dropzone.options.addRemoveLinks = true;
      });
    }
  },

  methods: {
    ...mapActions({
      showSuccessMessage: "alert/success",
      showErrorMessage: "alert/error",
    }),
    vsuccess() {
      this.success = true;
      this.showSuccessMessage(resources.STAGE_SUCCESS);
    },
    verror(file, message, xhr) {
      const errorMessage =
        xhr && xhr.status && xhr.statusText
          ? `${xhr.status}: ${xhr.statusText}`
          : resources.GENERIC_ERROR;

      file.previewElement.querySelectorAll(
        ".dz-error-message span"
      )[0].textContent = errorMessage;

      this.showErrorMessage(errorMessage);

      if (!this.errorFiles.includes(file.name)) {
        this.errorFiles.push(file.name);
      }
    },
    async vcanceled(file) {
      this.showErrorMessage(resources.CANCELED_UPLOAD_ERROR);
    },
    async vremoved(file) {
      if (!this.disableFileRemovalFromServer) {
        let fileType = this.title;
        try {
          if (this.errorFiles.includes(file.name)) {
            let updatedErrorFiles = this.errorFiles.filter(
              (e) => e !== file.name
            );
            this.errorFiles = updatedErrorFiles;
          } else {
            await FileService.deleteStagedFile(fileType, file.name);
          }
        } catch (err) {
          if (err.status && err.statusText) {
            this.showErrorMessage(`${err.status}: ${err.statusText}`);
          } else {
            this.showErrorMessage(resources.GENERIC_ERROR);
          }

          console.log(err);
        }
      }
    },
  },
};
</script>

<style>
.dropzone .dz-preview .dz-image {
  height: 100px;
}

.vue-dropzone > .dz-preview .dz-details {
  background-color: cadetblue !important;
}

.vue-dropzone > .dz-preview .dz-error-message {
  margin-left: auto;
  margin-right: auto;
  left: 0;
  width: 100%;
  text-align: center;
  top: 100%;
}

.vue-dropzone > .dz-preview .dz-remove {
  background: black;
  width: 100%;
  margin-left: 0;
  position: absolute;
  top: unset;
  bottom: 100%;
}

.dz-error-mark,
.dz-success-mark {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  top: 0 !important;
}
</style>
