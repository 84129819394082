var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dataTableContainer"},[_c('v-card',{staticClass:"dataTable"},[_c('v-card-title',[_vm._v(" "+_vm._s(((_vm.resources.USERS) + " (" + (_vm.users.length) + ")"))+" "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":("" + (_vm.resources.SEARCH)),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"search":_vm.search,"loading":_vm.loading},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.resources.CREATE_USER)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('validation-provider',{staticClass:"form-element",attrs:{"name":("" + (_vm.resources.USERNAME)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":("" + (_vm.resources.USERNAME)),"error-messages":errors,"required":""},model:{value:(_vm.editedItem.username),callback:function ($$v) {_vm.$set(_vm.editedItem, "username", $$v)},expression:"editedItem.username"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('v-checkbox',{attrs:{"label":"Active"},model:{value:(_vm.editedItem.isActive),callback:function ($$v) {_vm.$set(_vm.editedItem, "isActive", $$v)},expression:"editedItem.isActive"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('validation-provider',{staticClass:"form-element",attrs:{"vid":"password","name":("" + (_vm.resources.PASSWORD)),"rules":{ required: _vm.isEdit ? false : true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{ref:"password",attrs:{"error-messages":errors,"label":("" + (_vm.resources.PASSWORD)),"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.editedItem.password),callback:function ($$v) {_vm.$set(_vm.editedItem, "password", $$v)},expression:"editedItem.password"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('validation-provider',{staticClass:"form-element",attrs:{"name":("" + (_vm.resources.CONFIRM_PASSWORD)),"rules":{
                            passwordConfirmed: { password: '@password' },
                            required:
                              !_vm.isEdit || _vm.editedItem.password ? true : false,
                          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":("" + (_vm.resources.CONFIRM_PASSWORD)),"append-icon":_vm.showConfirmedPassword
                                ? 'mdi-eye'
                                : 'mdi-eye-off',"type":_vm.showConfirmedPassword ? 'text' : 'password'},on:{"click:append":function($event){_vm.showConfirmedPassword = !_vm.showConfirmedPassword}},model:{value:(_vm.editedItem.confirmedPassword),callback:function ($$v) {_vm.$set(_vm.editedItem, "confirmedPassword", $$v)},expression:"editedItem.confirmedPassword"}})]}}])})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.resources.CANCEL)+" ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.resources.SAVE)+" ")])],1)],1)],1),_c('confirm-dialog',{attrs:{"showDialog":_vm.dialogDelete,"onConfirm":_vm.deleteItemConfirm,"onCancel":_vm.closeDelete,"confirmationMessage":("" + (_vm.resources.CONFIRM_USER_DELETE)),"confirmText":("" + (_vm.resources.CONFIRM)),"cancelText":("" + (_vm.resources.CANCEL))}})],1)]},proxy:true},{key:"item.isActive",fn:function(ref){
                                var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.isActive),"dark":""}},[_vm._v(" "+_vm._s(item.isActive ? _vm.resources.ACTIVE : _vm.resources.INACTIVE)+" ")])]}},{key:"item.actions",fn:function(ref){
                                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }