<template>
  <div>
    <nav-menu></nav-menu>
    <file-upload-groups></file-upload-groups>
  </div>
</template>

<script>
  import NavMenu from '@/components/NavMenu';
  import FileUploadGroups from '@/components/FileUploadGroups';

  export default {
    name: 'Dashboard',
    components: { NavMenu, FileUploadGroups },
  }
</script>
