import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from './store';

Vue.config.productionTip = true;

new Vue({
  el: "#app",
  store,
  router,
  vuetify,
  template: "<App/>",
  components: { App },
  created: function () {
    console.log("Fetching configuration...");
  }
});
