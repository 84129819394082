<template>
  <div class="usersContent">
    <nav-menu></nav-menu>
    <users-table></users-table>
  </div>
</template>

<script>
  import NavMenu from '@/components/NavMenu';
  import UsersTable from '@/components/UsersTable';

  export default {
    name: 'Users',
    components: { NavMenu, UsersTable },
  }
</script>

<style>
  .usersContent {
    flex-grow: inherit;
  }
</style>